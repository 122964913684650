import axios from 'axios';

const baseUrl = 'https://gogates.org'; // Replace with your base URL
//const baseUrl = "https://pfqq055g-8800.euw.devtunnels.ms"
const getAuthToken = () => {
    return localStorage.getItem('authToken');
};

const createAxiosInstance = (locale) => {
    const authToken = getAuthToken();
    return axios.create({
        baseURL: `${baseUrl}/api/v1`,
        timeout: 20000, // Adjust timeout to 20 seconds
        headers: {
            'Accept-Language': locale,
            'Authorization': `Bearer ${authToken}`,
        }
    });
};

export default createAxiosInstance;
