// src/components/Home.js
import React from 'react';
import '../template/css/bootstrap.css';
import '../template/css/fontawesome.min.css';
import '../template/css/style.css';
import image1 from '../template/images/1.png';
import image22 from '../template/images/22.png';
import image33 from '../template/images/33.png';
import image44 from '../template/images/44.png';
import imageF from '../template/images/f.jpg';
import imageS from '../template/images/s.jpg';
import imageKk from '../template/images/kk.jpg';
import imageA from '../template/images/a.jpg';
import imageT from '../template/images/t.png';
import imageFb from '../template/images/f.png';
import imageI from '../template/images/i.png';
import imageY from '../template/images/y.png';
import imageG from '../template/images/g.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Home = () => {
    const { t } = useTranslation();
    return (
        <div>

            <div id="myslide" className="carousel slide" data-ride="carousel">
                <div className="container">
                    <ol className="carousel-indicators hidden-xs">
                        <li data-target="#myslide" data-slide-to="0" className="active"></li>
                        <li data-target="#myslide" data-slide-to="1"></li>
                        <li data-target="#myslide" data-slide-to="2"></li>
                        <li data-target="#myslide" data-slide-to="3"></li>
                    </ol>
                    <div className="carousel-inner" role="listbox">
                        <div className="item active">
                            <img src={image1} alt="..." />
                            <div className="carousel-caption hidden-xs">
                                <h1>{t('factory')}</h1>
                                <p>{t('factory_description')}</p>
                                <div className="btn btn-primary">{t("invite")}</div>
                                <div className="btn btn-danger">{t("read_more")}</div>
                            </div>
                        </div>
                        <div className="item">
                            <img src={image22} alt="..." />
                            <div className="carousel-caption hidden-xs">
                                <h1>{t('importer')}</h1>
                                <p>{t('importer_description')}</p>
                            </div>
                        </div>
                        <div className="item">
                            <img src={image33} alt="..." />
                            <div className="carousel-caption hidden-xs">
                                <h1>{t('link')}</h1>
                                <p>{t('link_description')}</p>
                            </div>
                        </div>
                        <div className="item">
                            <img src={image44} alt="..." />
                            <div className="carousel-caption hidden-xs">
                                <h1>{t('anywhere')}</h1>
                                <p>{t('anywhere_description')}</p>
                            </div>
                        </div>
                    </div>
                    <a className="left carousel-control" href="#myslide" role="button" data-slide="prev">
                        <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                        <span className="sr-only">{t('Previous')}</span>
                    </a>
                    <a className="right carousel-control" href="#myslide" role="button" data-slide="next">
                        <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                        <span className="sr-only">{t('Next')}</span>
                    </a>
                </div>
            </div>
            <section className="about text-center">
                <div className="container">
                    <h1 className="hidden-xs"><span>Go</span> gates</h1>
                    <h3 className="visible-xs"><span>Go</span> gates</h3>
                    <p className="lead">{t('about_description')}</p>
                </div>
            </section>
            <section className="features text-center">
                <div className="container">
                    <h1>{t('our_features')}</h1>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="feat">
                                <span className="glyphicon glyphicon-road" aria-hidden="true"></span>
                                <h3>{t('directions')}</h3>
                                <p>{t('directions_description')}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feat">
                                <span className="glyphicon glyphicon-time" aria-hidden="true"></span>
                                <h3>{t('the_deadlines')}</h3>
                                <p>{t('the_deadlines_description')}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feat">
                                <span className="glyphicon glyphicon-map-marker" aria-hidden="true"></span>
                                <h3>{t('location')}</h3>
                                <p>{t('location_description')}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feat">
                                <span className="glyphicon glyphicon-fullscreen" aria-hidden="true"></span>
                                <h3>{t('status')}</h3>
                                <p>{t('status_description')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials text-center">
                <div className="container">
                    <h1>{t('what_our_clients_say')}</h1>
                    <div id="testmonual" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="item active">
                                <p className="lead">{t("we_have_always_found_the_company_description")}</p>
                                <span>{t("alfudaily_custom_clearance_description")}</span>
                            </div>
                            <div className="item">
                                <p className="lead">{t("gogates_provide_distribution_services")}</p>
                                <span>{t("alsaif_transporter_description")}</span>
                            </div>
                            <div className="item">
                                <p className="lead">{t("gogates_with_our_customers_directly_description")}</p>
                                <span>{t("sabik_description")}</span>
                            </div>
                            <div className="item">
                                <p className="lead">{t("gogates_have_proven_themselves_to_be_a_valuable_description")}</p>
                                <span>{t("abd_lakreem_transporter_description")}</span>
                            </div>
                        </div>
                        <ol className="carousel-indicators">
                            <li data-target="#testmonual" data-slide-to="0" className="active">
                                <img src={imageF} alt="" />
                            </li>
                            <li data-target="#testmonual" data-slide-to="1">
                                <img src={imageS} alt="" />
                            </li>
                            <li data-target="#testmonual" data-slide-to="2">
                                <img src={imageKk} alt="" />
                            </li>
                            <li data-target="#testmonual" data-slide-to="3">
                                <img src={imageA} alt="" />
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
            <section className="helper text-center">
                <div className="container">
                    <h1>{t('our_services')}</h1>
                    <div className="row">
                        <div className="col-md-3 col-sm-6 col-xm-12">
                            <div className="helpers">
                                <h2 className="text-primary">{t("transporter")}</h2>
                                <p>{t("how_to_use")}</p>
                                <a href="https://www.youtube.com/@GoGates" className="btn btn-primary">{t('learn')}</a>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xm-12">
                            <div className="helpers">
                                <h2 className="text-danger">{t("factory")}</h2>
                                <p>{t("how_to_use")}</p>
                                <a href="https://www.youtube.com/@GoGates" className="btn btn-danger">{t('learn')}</a>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xm-12">
                            <div className="helpers">
                                <h2 className="text-warning">{t("custom_clearance")}</h2>
                                <p>{t("how_to_use")}</p>
                                <a href="https://www.youtube.com/@GoGates" className="btn btn-warning">{t('learn')}</a>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xm-12">
                            <div className="helpers">
                                <h2 className="text-info">{t("importer")}</h2>
                                <p>{t("how_to_use")}</p>
                                <a href="https://www.youtube.com/@GoGates" className="btn btn-info">{t('learn')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contactus text-center">
                <div className="fields">
                    <div className="container">
                        <div className="row">
                            <i className="fas fa-at fa-5x" style={{ color: '#ffd43b' }}></i>
                            <h1>{t('contact_us')}</h1>
                            <p className="lead">{t('feel_free_to_contact_us')}</p>
                            <form >
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder={t('name')} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder={t('email')} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder={t('phone_number')} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group input-lg">
                                        <textarea className="form-control" placeholder={t('your_message')}></textarea>
                                    </div>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">{t('send')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="statstic text-center">
                <div className="data">
                    <div className="container">
                        <h1>Statstics</h1>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="stats">
                                    <h2 className="text-primary">{t("transporter")}</h2>
                                    <p>7</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="stats">
                                    <h2 className="text-danger">{t("factory")}</h2>
                                    <p>12</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="stats">
                                    <h2 className="text-warning">{t("custom_clearance")}</h2>
                                    <p>45</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="stats">
                                    <h2 className="text-info">{t("importer")}</h2>
                                    <p>107</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h3>{t('sitemap')}</h3>
                            <ul className="list-unstyled">
                                <li>{t('home')}</li>
                                <li>{t('services')}</li>
                                <li>{t('about')}</li>
                                <li>{t('contact_us')}</li>
                                <li>{t('solutions')}</li>
                                <li><Link to="/privacy-policy">{t('privacy_notice')}</Link></li>
                                <li><a href="/files/gogates-Terms-of-Use.pdf">{t('terms_of_use')}</a></li>
                            </ul>
                            <ul className="list-unstyled social-list">
                                <li><a href="https://x.com/gogatesnet"><img src={imageT} alt="twitter" /></a></li>
                                <li><a href="https://www.facebook.com/profile.php?id=61562841375091"><img src={imageFb} alt="Facebook" /></a></li>
                                <li><a href="https://www.linkedin.com/in/gogates/"><img src={imageI} alt="linkedin" /></a></li>
                                <li><a href="https://www.youtube.com/@GoGates"><img src={imageY} alt="Youtube" /></a></li>
                                <li><img src={imageG} alt="gmail" /></li>
                            </ul>
                        </div>
                        <div className="col-lg-4">
                            <h3>{t('last_company')}</h3>
                            <ul className="list-unstyled">
                                <li>{t('alfudaily_custom_clearance')}</li>
                                <li>{t('alrasan_custom_clearance')}</li>
                                <li>{t('alhomain_factory')}</li>
                                <li>{t('allsmary_custom_clearance')}</li>
                                <li>{t('alrajhi_transporet')}</li>
                            </ul>
                        </div>
                        <div className="col-lg-4">
                            <h3>{t('map')}</h3>
                            <iframe
                                title='map'
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14903546.801014518!2d45.07408335!3d24.222141999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15e7b33fe7952a41%3A0x5960504bc21ab69b!2z2KfZhNiz2LnZiNiv2YrYqQ!5e0!3m2!1sar!2ssa!4v1721949656194!5m2!1sar!2ssa"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div className="copyright text-center">
                    Copyright &copy; 2023 <span>Go</span>.gates
                </div>
            </section>
        </div>
    );
};

export default Home;