import React, { useState, useEffect, useRef, useCallback } from 'react';
import { APIProvider, Marker, Map, useMap } from '@vis.gl/react-google-maps';
import createAxiosInstance from '../../api';
import { useTranslation } from 'react-i18next';

const MapComponent = () => {

    const [drivers, setDrivers] = useState([]);
    const [gates, setGates] = useState([]);
    const [gate, setGate] = useState('');
    const [error, setError] = useState(null);
    const { i18n } = useTranslation();
    const locale = i18n.language;
    const mapRef = useRef(null);
    const map = useMap();
    const { t } = useTranslation();

    const loadDrivers = async (gateId) => {
        try {
            const axiosInstance = createAxiosInstance(locale);
            const res = await axiosInstance.get(`/ordersdriver/mainMap/${gateId}`);
            if (res.status === 200) {
                setDrivers(res.data.data.map(od => od.drivers));
                console.log(res.data.data.map(od => od.drivers))
            }
        } catch (error) {
            setError('Failed to load drivers. Please try again.');
            console.log(error);
        }
    };

    const loadGates = useCallback(async () => {
        try {
            const axiosInstance = createAxiosInstance(locale);
            const res = await axiosInstance.get('/gates/all-type-gate');
            if (res.status === 200) {
                setGates(res.data.data);
            }
        } catch (error) {
            setError('Failed to load gates. Please try again.');
            console.log(error);
        }
    }, [locale]);

    useEffect(() => {
        loadGates();
    }, [loadGates]);

    useEffect(() => {
        if (mapRef.current === null) {
            console.log("mapRef.current is null. The Map component might not have been rendered yet.");
        } else {
            console.log(mapRef.current);
        }

        if (drivers.length > 0 && mapRef.current) {
            const bounds = new window.google.maps.LatLngBounds();
            drivers.forEach(driver => {
                if (driver.location.coordinates) {
                    const position = new window.google.maps.LatLng(driver.location.coordinates[0], driver.location.coordinates[1]);
                    bounds.extend(position);
                }
            });
            mapRef.current.fitBounds(bounds);
        }
    }, [drivers]);

    useEffect(() => {
        if (!map) return;
    }, [map]);

    const renderDriver = (driver) => {
        if (driver.location.coordinates) {
            const position = { lat: driver.location.coordinates[0], lng: driver.location.coordinates[1] };
            return (
                <Marker
                    position={position}
                    key={driver._id}
                    title={`Driver ID: ${driver._id}`}
                    onClick={() => {
                        alert(`Driver arabic name: ${driver.a_name}, english name: ${driver.e_name}, whatsapp: ${driver.w_phone}, phone: ${driver.phone}`);
                    }}
                />
            );
        }
        return null;
    };

    return (
        <div className="fields" style={{ backgroundImage: 'none' }}>
            <div className="container">
                <div className="row">
                    <h1>Map</h1>
                    <p className="lead">{t('select_a_gate_to_view_drivers')}</p>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <form>
                        <div className="form-group">
                            <label htmlFor="gate-select" className="form-label">Gate:</label>
                            <select
                                id="gate-select"
                                className="form-select"
                                value={gate}
                                onChange={(e) => {
                                    setGate(e.target.value);
                                    loadDrivers(e.target.value);
                                }}
                            >
                                <option value="">{t('select_a_gate')}</option>
                                {gates.map(gate => (
                                    <option key={gate._id} value={gate._id}>
                                        {`${gate.a_name} - ${gate.e_name}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </form>
                    <APIProvider apiKey={"AIzaSyD-wzU4j7bpUBOMJurWVpvdl_Qh-Bw4sGw"} >
                        {gate && <Map
                            onGoogleApiLoaded={({ map }) => {
                                const bounds = new window.google.maps.LatLngBounds();
                                drivers.forEach(driver => {
                                    if (driver.location.coordinates) {
                                        const position = new window.google.maps.LatLng(driver.location.coordinates[0], driver.location.coordinates[1]);
                                        bounds.extend(position);
                                    }
                                });
                                map.fitBounds(bounds);
                            }}

                            yesIWantToUseGoogleMapApiInternals
                            style={{ width: '75vw', height: '50vh' }}
                            defaultCenter={{ lat: 22.54992, lng: 0 }}
                            defaultZoom={3}
                            gestureHandling={'greedy'}
                            disableDefaultUI={true}
                        >
                            {drivers.map(driver => renderDriver(driver))}
                        </Map>}
                    </APIProvider>
                </div>
            </div>
        </div>
    );
};

export default MapComponent;