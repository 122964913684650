import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signIn, signOut } from '../../redux/reducers/authReducer';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
    const { t } = useTranslation();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            dispatch(signIn());
        }
    }, [dispatch]);

    const handleSignOut = () => {
        localStorage.removeItem('authToken');
        dispatch(signOut());
    };

    const handleMapClick = (e) => {
        if (!isLoggedIn) {
            e.preventDefault();
            navigate('/signin');
        }
    };

    const handleSignInClick = (e) => {
        e.preventDefault();
        navigate('/signin');
    };

    return (
        <nav className="navbar navbar-inverse navbar-fixed-top" role="navigation">
            <div className="container">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle"
                            data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="/"><span>Go</span> Gates</a>
                    </div>
                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav">
                            <li className="active"><a href="/">{t('home')}</a></li>
                            <li><a href="/map" onClick={handleMapClick}>{t('map')}</a></li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li><LanguageSwitcher /></li>
                            {!isLoggedIn ? (
                                <li><a href="/#" onClick={handleSignInClick}>{t('sign_in')}</a></li>
                            ) : (
                                <>
                                    <li><a href="/" onClick={handleSignOut}>{t('sign_out')}</a></li>
                                    <li className="dropdown">
                                        <a href="/#" className="dropdown-toggle" data-toggle="dropdown">{t('services')} <span className="caret"></span></a>
                                        <ul className="dropdown-menu" role="menu">
                                            <li><a href="/#">{t('login')}</a></li>
                                            <li><a href="/#">{t('contact_us')}</a></li>
                                            <li className="divider"></li>
                                            <li><a href="/#">{t('about_us')}</a></li>
                                        </ul>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;