import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = useCallback((lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
        document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
        document.documentElement.lang = lng;
    }, [i18n]);

    useEffect(() => {
        const savedLang = localStorage.getItem('language') || 'en';
        changeLanguage(savedLang);
    }, [changeLanguage]);

    return (
        <>
            {i18n.language === 'ar' ? (
                <a href="/#" onClick={() => changeLanguage('en')} style={{ color: 'white', cursor: 'pointer' }}>العربية</a>
            ) : (
                <a href="/#" onClick={() => changeLanguage('ar')} style={{ color: 'white', cursor: 'pointer' }}>English</a>

            )}
        </>
    );
};

export default LanguageSwitcher;