import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../../redux/reducers/authReducer';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import createAxiosInstance from '../../api';

const SignIn = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const locale = 'en-US';
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const axiosInstance = createAxiosInstance(locale);
        try {
            const response = await axiosInstance.post('/auth/login', {
                email,
                password
            });
            const { token } = response.data;
            localStorage.setItem('authToken', token); // Save the token in localStorage
            console.log('Login successful!', token);
            navigate('/map');
            dispatch(signIn());
            // Redirect or update the UI after successful login
        } catch (error) {
            setError('Login failed. Please check your credentials and try again.');
            console.error('There was an error logging in!', error);
        }
    };

    // Check if authToken exists in localStorage and redirect to /map if it does
    React.useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            navigate('/map');
        }
    }, [navigate]);
    return (
        <div className="fields" style={{ backgroundImage: 'none' }}>
            <div className="container">
                <div className="row">
                    <h1>Sign In</h1>
                    <p className="lead">{t('please_sign_in_to_continue')}</p>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                placeholder={t('email')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                placeholder={t('password')}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="btn btn-warning btn-lg btn-block">{t('sign_in')}</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignIn;